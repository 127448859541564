@import 'shared/content/icons.postcss';

.boeing-layout {
  font-size: 16px;
}

.boeing-layout body {
  background-color: #e6e6e6;
  max-width: 640px;
  margin: 0 auto;
  font-family: 'IBM Plex Sans', Arial, sans-serif;
  font-size: 1.125rem;
  line-height: 1.75rem;

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  p {
    margin: 0;
  }

  .BoeingContent {
    background-color: white;
    min-height: 100vh;
    overflow: auto;

    header {
      height: 9rem;
      background-image: url('https://static.seattlefoodtruck.com/photos/OfficeCater2.jpeg');
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      color: white;
      position: relative;

      p {
        font-size: 1rem;
      }

      .Overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 15px 20px;
      }
    }

    h1 {
      font-size: 1.5rem;
      margin: 0;
    }

    h2, h3 {
      margin: 0;
      font-size: 1.125rem;
    }

    & > div + div {
      border-top: 1px solid #d4d4d4;
    }
  }

  .HomeInfo,
  .Location a,
  .BoeingEvent,
  .BoeingTruck a {
    padding: 20px;
  }

  a.BoeingCTA {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 50px;
    padding: 5px 20px;
    background: white;
    color: black;
    position: absolute;
    top: 15px;
    right: 20px;

    &:hover {
      color: #7f7f7f;
    }
  }

  .Location a,
  .BoeingTruck a {
    display: flex;
    align-items: center;
  }

  .LocationIcon {
    width: 64px;
    height: 64px;
    margin-right: 20px;
    background-color: #204062;
    border-radius: 50%;
    flex-shrink: 0;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    &-img {
      border-radius: 50%;
      width: 64px;
      height: 64px;
      background-size: cover;
      background-position: center center;
    }

    &--smallText {
      font-size: 1rem;
    }
  }

  .LocationInfo,
  .BoeingTruck {
    p {
      color: #737373;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .Info {
    padding: 80px 20px;
    text-align: center;

    a {
      color: white;
      background-color: #204062;
      padding: .5rem 1.5rem;
      border-radius: 24px;
      font-weight: 600;
      font-size: 1rem;

      &:hover {
        background-color: #162c44;
      }
    }
  }

  .Support {
    font-size: 1rem;
    text-align: center;
    margin: 0 auto;

    p {
      margin: 40px 20px;
    }

    .bold {
      font-weight: 600;
    }
  }

  .DatePagination {
    display: flex;
    justify-content: space-between;

    & > h2 {
      padding: 15px 20px;
    }

    & > div {
      padding: 15px;
    }


    &-back, &-next {
      min-width: 70px;
      cursor: pointer;
      font-size: 1rem;
      padding: 0 5px;

      &:hover {
        opacity: 0.5;
      }

      &--disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }

  .icon:before {
    display: inline-block;
    font-family: "icons";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle;
    padding-bottom: 2px;
  }
  .icon-arrow-left:before { content: "\EA04" }
  .icon-arrow-right:before { content: "\EA05" }

}
